import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  styled
} from "@mui/material";

import {Unit} from "services/signup";

const StyledSelect = styled(Select)({
  height: "56px",
  paddingTop: 0,
  paddingBottom: 0,
  "& .MuiListItemText-primary": {
    fontSize: "1rem"
  },
  "& .MuiListItemText-secondary": {
    fontSize: "1rem",
    lineHeight: 1
  }
});

interface UnitSelectorProps {
  onChange: any;
  value: string;
  options: { unit: Unit }[];
}

const UnitSelector = ({options, onChange, value}: UnitSelectorProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel variant="outlined" id="unitId">
            Przynależność
          </InputLabel>
          <StyledSelect
            labelId="unitId"
            id="unitId"
            variant="outlined"
            onChange={onChange}
            value={value}
            label="Przynależność"
            fullWidth
            displayEmpty
          >
            {options.map(({unit}) => {
              return (
                <MenuItem key={unit.id} value={unit.id}>
                  <ListItemText
                    primary={unit.name}
                    secondary={`${unit.address}, ${unit.postal_code} ${unit.city}`}
                  />
                </MenuItem>
              );
            })}
          </StyledSelect>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default UnitSelector;

import React, {useCallback, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import {
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import {graphql, navigate} from "gatsby";
import InputMask from "react-input-mask";
import PasswordInput from "components/atoms/PasswordInput";
import {ROUTES_PL} from "config";
import {latinRegExp} from "utils";
import {
  finishUserSignUp,
  getSignUpUser,
  getUnits,
  UnitProposalResponse,
} from "services/signup";
import UnitSelector from "components/atoms/UnitSelector";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import SectionTitle from "components/atoms/SectionTitle";

// TODO: Extract locales

YupPassword(Yup);

interface FinishRegistrationFormValues {
  first_name: string;
  last_name: string;
  email: string;
  pesel: string;
  street_name: string;
  building_number: string;
  flat_number: string | null;
  city: string;
  postal_code: string;
  phone: string;
  password: string;
  unit_id: string;
  physician_id: string;
  nurse_id: string;
}

Yup.addMethod<Yup.StringSchema>(Yup.string, "isValidPESEL", function () {
  return this.test({
    name: "isValidPESEL",
    message: "PESEL jest nieprawidłowy",
    test: (pesel: string = "") => {
      let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
      let sum = 0;
      let controlNumber = parseInt(pesel.substring(10, 11));

      for (let i = 0; i < weight.length; i++) {
        sum += parseInt(pesel.substring(i, i + 1)) * weight[i];
      }
      sum = sum % 10;
      return (10 - sum) % 10 === controlNumber;
    },
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, "isAdultBasedOnPESEL", function () {
  return this.test({
    name: "isAdultBasedOnPESEL",
    message: "Rejestracja online jest dozwolona tylko dla osób pełnoletnich",
    test: (pesel: string = "") => {
      let year = 1900 + parseInt(pesel[0]) * 10 + parseInt(pesel[1]);
      if (parseInt(pesel[2]) >= 2 && parseInt(pesel[2]) < 8)
        year += Math.floor(parseInt(pesel[2]) / 2) * 100;
      if (parseInt(pesel[2]) >= 8)
        year -= 100;

      const month = (parseInt(pesel[2]) % 2) * 10 + parseInt(pesel[3]);
      const day = parseInt(pesel[4]) * 10 + parseInt(pesel[5]);
      const today = new Date();
      let age = today.getFullYear() - year;
      if (today.getMonth() < (month - 1) || (today.getMonth() === (month - 1) && today.getDate() < day))
        age--

      return age >= 18
    }
  })
})

const validationSchema = Yup.object<Omit<FinishRegistrationFormValues, "nurse_id" | "physician_id">>({
  first_name: Yup.string().trim().matches(latinRegExp, "Wymagane litery łacińskie").required("To pole jest wymagane"),
  last_name: Yup.string().trim().matches(latinRegExp, "Wymagane litery łacińskie").required("To pole jest wymagane"),
  email: Yup.string().trim().required("To pole jest wymagane"),
  pesel: Yup.string().trim().required("To pole jest wymagane").isValidPESEL().isAdultBasedOnPESEL(),
  postal_code: Yup.string().trim().required("To pole jest wymagane"),
  building_number: Yup.string().trim(),
  flat_number: Yup.string().trim(),
  phone: Yup.string()
    .trim()
    .length(15, "To pole jest wymagane")
    .required("To pole jest wymagane"),
  password: Yup.string()
    .minUppercase(1, "Hasło powinno składać się z minimum jednej dużej litery.")
    .min(8, "Hasło powinno składać się z minimum ośmiu znaków.")
    .trim()
    .required("To pole jest wymagane."),
  street_name: Yup.string().matches(latinRegExp, "Wymagane litery łacińskie").trim().required("To pole jest wymagane"),
  city: Yup.string().trim().matches(latinRegExp, "Wymagane litery łacińskie").required("To pole jest wymagane"),
  unit_id: Yup.string().trim().required("To pole jest wymagane"),
});

export default function FinishRegistrationForm() {
  const [units, setUnits] = useState<UnitProposalResponse["unit_proposals"]>(
    []
  );
  const [submitToken, setSubmitToken] = useState<string>();

  const initialValues: FinishRegistrationFormValues = {
    first_name: "",
    last_name: "",
    email: "",
    pesel: "",
    building_number: "",
    street_name: "",
    flat_number: "",
    city: "",
    password: "",
    phone: "",
    postal_code: "",
    unit_id: "",
    physician_id: "",
    nurse_id: "",
  };
  const onSubmit = async (values: FinishRegistrationFormValues) => {
    try {
      const transformedValues = values;
      transformedValues.phone = values.phone
        .replace(/ /g, "")
        .replace("+48", "");
      transformedValues.flat_number = values.flat_number || null;
      await finishUserSignUp(transformedValues, submitToken!);
      navigate(ROUTES_PL.registerThankYou);
    } catch (e) {
      alert("Wystąpił nieznany błąd. Prosimy o kontakt dok@dokdok.pl");
    }
  };

  const getUnitProposals = useCallback(async () => {
    const {unit_proposals} = await getUnits();
    setUnits(unit_proposals);
    const unit = unit_proposals[0]?.unit;
    if (unit) {
      formik.setFormikState((state) => ({
        ...state,
        values: {
          ...state.values,
          unit_id: unit.id,
          physician_id: unit.physician.id,
          nurse_id: unit.nurse.id,
        },
      }));
    }
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const getUserData = useCallback(async (token: string) => {
    try {
      const user = await getSignUpUser(token);
      setSubmitToken(token);

      formik.setFormikState((state) => ({
        ...state,
        values: {
          ...state.values,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        },
      }));
    } catch {
      navigate("/register", {replace: true});
    }
  }, []);

  const onUnitChange = (value: any) => {
    const unitId = value.target.value;
    const unit = units.find((unit: any) => unit.unit.id === unitId);
    formik.setFieldValue("unit_id", unit?.unit.id);
    formik.setFieldValue("physician_id", unit?.unit.physician.id);
    formik.setFieldValue("nurse_id", unit?.unit.nurse.id);
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const token = search.get("token");
    if (!token) {
      navigate("/register", {replace: true});
    }
    getUnitProposals();
    getUserData(token as string);
  }, []);

  if (!submitToken) {
    return <></>;
  }

  return (
    <SiteLayout>
      <Section padding={4}>
        <SectionTitle>
          Rejestracja do przychodni DokDok
        </SectionTitle>
        <form onSubmit={formik.handleSubmit} style={{padding: "50px 0"}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                color="primary"
                name="first_name"
                autoComplete="given-name"
                label="Imię"
                type="text"
                variant="outlined"
                fullWidth
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                error={formik.touched.first_name && !!formik.errors.first_name}
                helperText={formik.touched.first_name && formik.errors.first_name}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                color="primary"
                name="last_name"
                autoComplete="family-name"
                label="Nazwisko"
                type="text"
                variant="outlined"
                fullWidth
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                error={formik.touched.last_name && !!formik.errors.last_name}
                helperText={formik.touched.last_name && formik.errors.last_name}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                color="primary"
                name="pesel"
                autoComplete="off"
                label="PESEL"
                type="text"
                variant="outlined"
                inputProps={{
                  maxLength: 11,
                }}
                fullWidth
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pesel}
                error={formik.touched.pesel && !!formik.errors.pesel}
                helperText={
                  formik.touched.pesel
                    ? formik.errors.pesel
                    : "Wymagany przez NFZ, aby uniknąć opłat."
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                color="primary"
                name="street_name"
                autoComplete="on"
                label="Ulica"
                type="text"
                variant="outlined"
                fullWidth
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.street_name}
                error={formik.touched.street_name && !!formik.errors.street_name}
                helperText={formik.touched.street_name && formik.errors.street_name}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid container item xs={12} md={4} justifyContent="space-between" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  color="primary"
                  name="building_number"
                  autoComplete="on"
                  label="Numer budynku"
                  type="text"
                  variant="outlined"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.building_number}
                  error={
                    formik.touched.building_number &&
                    !!formik.errors.building_number
                  }
                  helperText={
                    formik.touched.building_number && formik.errors.building_number
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  color="primary"
                  name="flat_number"
                  autoComplete="off"
                  label="Numer lokalu"
                  type="text"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.flat_number}
                  error={formik.touched.flat_number && !!formik.errors.flat_number}
                  helperText={
                    formik.touched.flat_number && formik.errors.flat_number
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}/>
            <Grid item xs={12} md={4}>
              <TextField
                color="primary"
                name="city"
                autoComplete="address-level2"
                label="Miasto"
                type="text"
                variant="outlined"
                fullWidth
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                error={formik.touched.city && !!formik.errors.city}
                helperText={formik.touched.city && formik.errors.city}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item container xs={12} md={4}>
              <Grid item xs={6}>
                <InputMask
                  mask="99-999"
                  alwaysShowMask
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur(e)
                    const postalCode = formik.values.postal_code;
                    if (postalCode) {
                      const unitMatch = units.find(unit => unit.unit.postal_code[0] === postalCode[0])
                      if (unitMatch) {
                        formik.setFieldValue("unit_id", unitMatch.unit.id)
                        formik.setFieldValue("physician_id", unitMatch.unit.physician.id);
                        formik.setFieldValue("nurse_id", unitMatch.unit.nurse.id);
                      }
                    }
                  }}
                  value={formik.values.postal_code}
                >
                  {() => (
                    <TextField
                      color="primary"
                      name="postal_code"
                      autoComplete="postal-code"
                      label="Kod pocztowy"
                      type="text"
                      variant="outlined"
                      required
                      error={
                        formik.touched.postal_code && !!formik.errors.postal_code
                      }
                      helperText={
                        formik.touched.postal_code && formik.errors.postal_code
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={4}>
              <Grid item xs>
                <UnitSelector
                  onChange={onUnitChange}
                  value={formik.values.unit_id}
                  options={units}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                color="primary"
                name="email"
                autoComplete="email"
                label="Adres email"
                type="text"
                variant="outlined"
                fullWidth
                disabled
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={"To będzie twój login do aplikacji mobilnej DokDok"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PasswordInput
                variant="outlined"
                name="password"
                label="Hasło"
                fullWidth
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && !!formik.errors.password}
                helperText={
                  formik.touched.password
                    ? formik.errors.password
                    : "To będzie twoje haslo do aplikacji mobilnej DokDok"
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputMask
                mask="+4\8 999 999 999"
                maskChar=" "
                alwaysShowMask
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              >
                {() => (
                  <TextField
                    color="primary"
                    name="phone"
                    autoComplete="tel"
                    label="Numer telefonu"
                    type="text"
                    variant="outlined"
                    fullWidth
                    required
                    error={formik.touched.phone && !!formik.errors.phone}
                    helperText={formik.touched.phone && formik.errors.phone}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <FormControl margin="normal">
                <Button
                  disabled={!(formik.isValid && formik.dirty)}
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Zarejestruj się
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Section>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
